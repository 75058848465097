export default {
  // message:{
  navbar: {
    Home: 'Homepage',
    live: 'Live',
    MCN: 'MCN',
    shop: 'E-commerce',
    game: 'Game',
    Brand: 'Brand Service',
    aboutUs: 'About Starlight'
  },
  news:{
    time:"Time",
    anther:"From Starlight Bright",
  },
  // 底部
  footer: {
    contentUs: 'Contact Us',
    cpAddress: 'Company Address',
    Address: 'Address：',
    gzUs: 'Follow Us',
    wechat: 'Official Account',
    Dy: 'Official Tiktok',
    Sp: 'Official Channel',
    WeiBo: 'Official WeiBo',
    KfPhone:'Customer Phone',
    weXin:'WeChat',
    severPhone:'Service Phone',
  },
  // 首页
  index: {
    isHere: 'Starlight is here ',
    HaxingLive: 'Starlight Live',
    HuaxingMCN: 'Starlight MCN',
    HuaxingGames: 'Starlight Games',
    Ecommerce: 'Starlight E-commerce'
  },
  LIVE: {
    headAnchor: 'Elite Host',
    hotAnchor: 'Hot Host',
    entertainmentStar: 'Entertainment Express',
    brandText: 'Brand/product：',
    intention: 'Intentional Host',
    cooperationNeeds: 'Cooperation Needs Brief Introduction：',
    brandDirectSale: 'Is it brand direct sale ',
    wechat: 'Wechat ID',
    phone: 'Tel',
    submitText: 'Submit',
    businessCooperation: 'Business Cooperation',
    city: 'City：',
    rules_brandText: 'Please enter brand /product',
    rules_intention: 'Please enter intentional host',
    rules_cooperationNeeds: 'Please enter cooperation needs brief introduction',
    rules_brandDirectSale: 'Please enter whether it is brand direct sale',
    rules_wechat: 'Please enter Wechat ID',
    rules_phone: 'Please enter Tel',
    rules_city: 'Please enter your city',
    rules_TiktokID: 'Please enter Tiktok ID(Channel ID)',
    rules_age: 'Please enter age ',
    rules_sex: 'Please enter gender',
    rules_style: 'Please enter style type',
    anchorRecruitment: 'Host Recruitment',
    TiktokID: 'Tiktok ID(Channel ID)',
    sex: 'Gender：',
    age: 'Age：',
    style: 'Style Type：',
    cooperativeBrand: 'Cooperative Brand',
    Zhaijid: 'Self-incubated Account-Zhaijidi',
    headMaster: 'Elite KOL',
    hotMaster: 'Hot KOL',
    HighCases: 'High-quality advertising case',
    PopularVideo: 'Popular Video',
    AdvertisingHit: 'KOL with popular advertisment',
    TalentRecruitment: 'KOL Recruitment',
    broadcastingPlatform: 'Live Platform：',
    LiveAccount: ' Live Account：',
    expertise: 'Areas of expertise',
    broadcastResults: 'Brief summary of previous live performances:',
    rules_platform: 'Please enter live platform',
    rules_accout: 'Please enter live account',
    rules_good: 'Please enter your area of expertise',
    liveId: 'Tiktok ID',
    TiktokFans: 'Tiktok Fans',
    TiktokLikes: 'Tiktok Likes',
    strictSelection: 'Starlight Strict Selection',
    PopularCases: ' Popular Cases',
    RecentInformation: 'Recent Information',
    moreNews: 'More News',
    noMore: 'No More',
    newsStar: 'Starlight Latest news',
    LearnMore: 'Learn More',
    LearnHuaxing: 'Learn Starlight',
    BrightChineseStars: 'Starlight Bright',
    signedArtist: 'KOL who have signed up on all social platforms',
    ServiceArtist: 'KOL who have served on all social platforms',
    NumberOfFans: 'Fans amount of all social platforms'
  },
  game: {
    GameMasters: 'Game kol',
    QualityCases: 'High-quality Cases（game business）',
    ClickView: 'Click to View',
    RecentNews: 'Recent Information',
    GameRecruitment: 'Game KOL Recruitment',
    formPT: 'The current short video platform：',
    formGame: ' Skilled Game ：',
    formFans: 'Current Fans Amount',
    formCZ: 'Whether Can create videos independently：',
    titleMCN: 'National top-level game MCN agency',
    contentMCN: 'In 2018, it became the official content supplier of Penguin E-sports and Douyin Games. Cooperated with more than 50 game developers and game IPs. With game KOLs over 3000, it has independently hatched multiple IPs such as Xiaoyu-Game expert , Sao Ao, Kuang Zhan Shen aoladan, etc. The content covers game recommendations, game evaluations, game live broadcasts, highlight moments, popular events, etc., It provides content and services for multiple short video platforms. Reached a strategic partnership with Tencent Advertising on the WeChat video account mutual selection platform. ',
    rulePT: 'Please enter your current short video platform',
    ruleGame: 'Please enter your skilled game ',
    ruleFans: 'Please enter current fans amount',
    ruleCZ: 'Please enter whether can create videos independently'
  },
  Brand: {
    BrandService: ' Brand Service',
    BrandContent: "Create a short video platform service ecosystem. Serving nearly 100 brand customers, serving brand customers wholeheartedly, forming a closed supply loop for brand customer demand, providing integrated marketing planning for the whole platform, creating a brand IP image, forming a classic brand reputation on the entire network, and popular Internet. Its department consists of two main business sectors, the agent sector and the agency operation sector. As the core agent of Douyin and Kuaishou, it promotes products for the brand, establishes high-quality official stores, short video accounts, etc., and sells goods in series with the company's e-commerce. , effect live broadcast, store live broadcast and other business sectors, forming a closed loop of promotion, focusing on the development of short video platform business, comprehensive and efficient services, and providing a one-stop worry-free business model'. ",
    cooperationCases: 'High-quality Cooperation Cases',
    CooperativeBrands: 'Cooperative Brands'
  },
  about: {
    hxTitle: 'Starlight Bright',
    hxTitleSup: 'Starlight Headquarters Industrial Park',
    hxTitleEn: 'Starlight Headquarters Industrial Park',
    hxYQ: 'Company Introduction',
    hxYQContent: 'Founded in 2018, Starlight Bright is a wholly-owned subsidiary of the independent brand of "Starlight Brothers". At present, the company is vigorously developing five major businesses including live broadcast, MCN, E-commerce, Games, and Brand Services, covering multiple new media platforms such as Douyin, Channel, Kuaishou, Douyu, Momo, and Tencent NOW. It is the head live broadcast brokerage company in the country. , MCN service organization, e-commerce live broadcast service provider. It has won the honor of Chengdu New Economy Top 100 Enterprises, Unicorn Incubation Enterprises, Executive Director Unit of China Performance Industry Association Network Performance Branch, etc.。',
    hxYQContentE: 'Starlight Bright Headquarters is established in Chengdu Eastern Suburb Memory Internet Creative Industry Park, with a team size of over 600 people. Direct companies have been established in many provinces and cities across the country, including Beijing, Hangzhou, and Panzhihua. It continues to make breakthroughs in development and form a service-oriented enterprise with nationwide business operations. Starlight Bright serves over 100,000+ KOLs, has many top and high-quality contracted KOLs, has over 1 billion fans across the entire network, over 10 billion+ likes for videos, and over 100 billion+ views for videos. As a high-quality content provider and KOL service provider, it has reached strategic cooperation with major short video, new media platforms and many well-known brands across the country.。',
    hxYQContentT: 'In the future, Starlight Bright will continue to expand the development of the ecological industry, improve the construction of the service system, and focus on being a comprehensive, new economy, and new media high-quality service-oriented international enterprise。',
    lOne: 'Building 1',
    lOneName: 'Headquarters building',
    lTwo: 'Building 2',
    lTwoName: 'MCN：advertisement、game、brand',
    lThree: 'Building 4a',
    lThreeName: 'Administrative Reception',
    lFour: 'Building 4b',
    lFourName: 'Personnel Reception',
    lFive: 'Building 5',
    lFiveName: 'Live ：Economic and refined operation business line',
    lSix: 'Building 6',
    lSixName: 'Live：Live broadcast operation business line',
    lSeven: 'Building 7',
    lSevenName: 'E-commerce:zhaijidi',
    lEight: 'Building 8',
    lEightName: 'Live content',
    lNine: 'Building 10',
    lNineName: 'E-commerce：Starlight strict selection',
    hxFZ: 'Development history',
    hxRY: 'Starlight Honour',
    hxNews: 'Starlight News',
    hxMore: 'More News',
    hxNoMore: 'No More'
  }
  // }
}
