<template>
  <div id="app">
    <NavItem></NavItem>
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
    <MapChart v-if='!$route.meta.hideNavMenu'></MapChart>
    <footer-tail v-if='!$route.meta.hideNavMenu'></footer-tail>
  </div>
</template>
<script>
import NavItem from '@/components/navItem'
import FooterTail from '@/components/footerTail'
import MapChart from '../src/components/mapChart'
export default {
  name: 'App',
  components: {
    NavItem ,
    FooterTail,
    MapChart
  },
  provide() {
    return {
      // reload: this.reload,
    }
  },
  data() {
    return {
      // isRouterAlive: true,
      bannerUrl: []
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleSelect:function(){

    },
    banner(data) {
      console.log('******************',data)
      this.bannerUrl = data
    }
  }
}
</script>
<style lang='scss'>
.app{
  width: 100%;
}
#app{
  position: relative;
}
html {overflow: hidden;height: 100%;} // 锁定视频弹窗的外层页面滚动条
body {overflow: auto;height: 100%;}
* {
  padding: 0;
  margin: 0;
}
.el-footer{
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
