<template>
  <div :class="[activeIndex == '/' ? 'backgroundHeader' : '', 'header w']">
    <el-row style='width: 100%;height: 100%;'>
      <el-col :span='6'>
        <div class='logo'>
          <img v-if="activeIndex == '/'" src='../../src/assets/cuicanLogo_2.png' alt />
          <img v-else src='../../src/assets/cuicanlogo.png' alt />
        </div>
      </el-col>
      <el-col :span='12' style='justify-content: center'>
        <div :class="[activeIndex == '/' ? 'nav-s' : '', 'nav']" style='height:100%;line-height: 80px'>
          <ul style='height:100%;line-height: 80px'>
            <li v-for='(item,index) in routes' :key='index' v-if='!item.meta.hideNavMenu'
                :class="activeIndex == item.path ? 'activeColor' : ''">
              <router-link :to='item.path'>
                <p translate='no' style='position: relative'>
                  <span>{{ $t(`navbar.${item.name}`, { title: item.meta.title }) }}</span>
                  <span class='line_s'></span>
                </p>
              </router-link>
              <!-- <div
                :class="activeIndex == index ? 'activeColor' : ''"
                @click="changeColor(index,item.url)"
              >{{item.name}}</div>-->
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span='6' style='justify-content: flex-end'>
        <el-dropdown trigger='click' @command='changeIanguage'>
                     <span class='el-dropdown-link'>
                       {{ language }}<i class='el-icon-arrow-down el-icon--right'></i>
                     </span>
          <el-dropdown-menu slot='dropdown'>
            <el-dropdown-item command='en'>English</el-dropdown-item>
            <el-dropdown-item command='th'>ภาษาไทย</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>


    <!--    <div class='lang-item'>-->
    <!--      <span :class="language=='zh'?'isColor':''" @click="changeIanguage('zh')">中文</span>/-->
    <!--      <span :class="language=='en'?'isColor':''" @click="changeIanguage('en')">EN</span>/-->
    <!--      <span :class="language=='Thai'?'isColor':''" @click="changeIanguage('Thai')">Thai</span>-->
    <!--    </div>-->


    <div class='topBack'>
      <top></top>
    </div>
  </div>
</template>

<script>
import routes from '../router'
import { Nav, Logo } from '@/api/banner'
import top from '../views/top'
// import translator from '@sternelee/translator-worker'
export default {
  name: 'navItem',
  components: {
    top
  },
  directives: {},
  data() {
    return {
      List: [],
      routes: [],
      activeIndex: '/',
      logo: {},
      language: ''
    }
  },
  created() {
    // this.getAllBanner()
    let language = localStorage.getItem('locale')
  },
  mounted() {
    // console.log('-------------routes', routes)
    this.routes = routes
    // this.getAllNav()
    // this.getlogo()
    if (localStorage.getItem('locale') == 'en') {
      this.language = 'English'
      localStorage.setItem('locale', 'en')
      this.$i18n.locale = 'en' //这个代码负责实时切换语言
    } else if (localStorage.getItem('locale') == 'th') {
      this.language = 'ภาษาไทย'
      localStorage.setItem('locale', 'th')
      this.$i18n.locale = 'th' //这个代码负责实时切换语言
    } else {
      this.language = 'ภาษาไทย'
      localStorage.setItem('locale', 'th')
      this.$i18n.locale = 'th' //这个代码负责实时切换语言
    }

  },
  watch: {
    $route() {
      this.activeIndex = this.$route.path
      console.log(this.activeIndex)
      // setTimeout(() => { //路由跳转
      //     this.$refs.menu.activeIndex = i
      // }, 100)
    },
    language: {
      immediate: true,
      deep: true,
      handler: function(val) {
        console.log(345345, val)
      }
    }
  },
  methods: {
    // 改变语言
    changeIanguage(language) {
      if (language == 'en') {
        this.$set(this, 'language', 'English')
      } else if (language == 'th') {
        this.$set(this, 'language', 'ภาษาไทย')
      } else {
        this.$set(this, 'language', 'ภาษาไทย')
      }
      localStorage.setItem('locale', language)
      this.$i18n.locale = language //这个代码负责实时切换语言
      location.reload()
      // let m = {
      //   live: {
      //     sadfs: '头部主播'
      //   }
      // }
      // let textInner = this.$t('navbar');
      // this.$i18n.setLocaleMessage('zh', {...this.$i18n.getLocaleMessage('zh'), ...m })
      // console.log(this.$i18n.getLocaleMessage('zh'), textInner)
      // this.$i18n.setLocaleMessage(e, res.data);
    },
    link: function(url, val) {
      console.log(url, val)
      this.activeIndex = val
      this.$router.push({
        path: url
      })
    },
    changeColor(index, url) {
      console.log(index)
      this.$router.push(url)
      this.activeIndex = index
    },
    // 获取到导航
    getAllNav() {
      Nav('sort', 1)
        .then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.List = res.data.data
          }
        })
        .catch()
    },
    // 获取logo
    getlogo() {
      Logo()
        .then(res => {
          console.log('-----------------------', res)
          if (res.data.code == 200) {
            this.logo = res.data.data
            sessionStorage.setItem('developmentCourse', res.data.data.development_course)
          }
        })
        .catch()
    }
  }
}
</script>

<style lang='scss' scoped>

pre {
  font-family: none !important;
  white-space: nowrap !important;;
}

ul {
  list-style: none;
}

/* 顶部导航 */
.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  //background: linear-gradient(-90deg, #E84B24, #FFFFFF);
  //padding-right: 20px;
  color: #333
}

.backgroundHeader {
  //background: linear-gradient(-90deg, #E84B24, #FFFFFF);
  position: absolute;
  width: 100%;
  z-index: 11;
}

.logo {
  width: 300px;
  //height: 42px;
  /* border: 1px solid red; */
  margin-left: 58px;
  margin-right: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  //margin-top:10px;
}

.logo img {
  width: 100%;
  /*height: 100%;*/
}

.nav ul {
  display: flex;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333;
  height: 100%;
}

.nav ul li {
  padding: 0px 20px;
  //margin-right: 54px;
  height: 80px;
  color: #333333;
  line-height: 80px;
  cursor: pointer;
}

.nav ul li:hover {
  color: #e44720;
}

.activeColor .line_s {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e44720;
  position: absolute;
  bottom: 27px;
}

.lang-item {
  padding-right: 10px;

  span {
    cursor: pointer;
  }
}

.isColor {
  color: #fff;
}

.topBack {
  position: absolute;
}

.header .el-dropdown {
  width: 115px;
}

.header .el-dropdown-link {
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
}

.backgroundHeader .el-dropdown-link {
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
}

.el-col-12 {
  height: 100%;
  display: flex;
  align-items: center;
}
.el-col-6 {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
