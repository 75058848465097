// import Vue from 'vue'
import axios from 'axios'
// import { showLoading, hideLoading } from 'components/loading'

const service = axios.create({
  // baseURL:'/api'
  baseURL: 'http://th.api.hxxd.tv', // api 的 base_url
  // baseURL: 'http://newapi.test.hxxd.tv', // api 的 base_url
  // baseURL: 'http://local.website.cn', // api 的 base_url
//   method: 'get', // default
//   withCredentials: true,
  // timeout: 15000 // 请求超时时间
})

// // 添加请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情
    config.headers.lang = `${localStorage.getItem('locale') ? localStorage.getItem('locale') : 'th'}`;
    return config
  },
  error => {
    // 做一些有请求错误的事情
    return Promise.reject(error);
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    let res=response.data
    if (res.status === 200 || res.status === 400 || res.status === 403 || res.status === 405) {
      return res
    } else if (res.status === 401) {
      return
    }else {
      return response
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
