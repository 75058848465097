import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // 走进华星
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { title: '首页' }
  },
  // 直播
  {
    path: '/live',
    name: 'live',
    component: () => import('../views/live.vue'),
    meta: { title: '直播' },
  },
  // MCN
  {
    path: '/MCN',
    name: 'MCN',
    component: () => import('../views/MCN.vue'),
    meta: { title: 'MCN' },
  },
  // 电商
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/shop.vue'),
    meta: { title: '电商' },
  },
  // 游戏
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game.vue'),
    meta: { title: '游戏' },
  },
  {
    path: '/Brand',
    name: 'Brand',
    component: () => import('../views/Brand.vue'),
    meta: { title: '品牌服务' },
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue'),
    meta: { title: '关于华星' },
  },
  // 新闻详情
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('../components/newsDetails'),
    meta: { title: '新闻详情',hideNavMenu: true },
  },
]

export default routes
